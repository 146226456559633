import React from 'react'
import styled from 'styled-components'
import { gql } from '@apollo/client'
import { QuickLinksFragment } from './__generated__/quickLinks'
import { QuickLinks as QuickLinksComponent } from '../../../components/QuickLinks/QuickLinks'
import { isNotNull, nonNull } from '../../../utils/nonNull'
import { StyledCardContainer } from '../../../components/QuickLinks/styledQuickLinksCard'

export type QuickLinksProps = {
  data: QuickLinksFragment[]
}

const StyledQuickLinksComponentWrapper = styled.div`
  div[class*=styledAccordionCard__StyledAccordionDetails] {
    padding-top: 0;
    ${props => props.theme.breakpoints.up('sm')} {
      padding-top: unset;
    }
  }

  ${StyledCardContainer} {
    ${props => props.theme.breakpoints.down('sm') && 'min-height: 86px;'}
    .MuiAccordionSummary-content {
      margin-top: 24px;
    }
  }
`

export const QuickLinks: React.FC<QuickLinksProps> = ({ data }) => data ? (
  <StyledQuickLinksComponentWrapper>
    <QuickLinksComponent>
      {nonNull(data).map((quickLink, i) => quickLink.__typename !== 'homePageQuickLinks_BlockType' ? null : (
      // @ts-ignore
        <QuickLinksComponent key={i} card variant={false} iconCode={quickLink.icon} title={quickLink.quickLinkTitle} href={quickLink?.quickLinkUrl?.url || '#'} target={quickLink?.quickLinkUrl?.target === '_blank' ? '_blank' : '_self'}>
          {quickLink.quickLinks?.filter(isNotNull).map((subLink, i) => (subLink.__typename !== 'quickLinks_quickLink_BlockType' ? null :
            <QuickLinksComponent key={i} item href={subLink?.subQuickLinkUrl?.url || '#'} target={subLink?.subQuickLinkUrl?.target === '_blank' ? '_blank' : '_self'}>
              {subLink.text}
            </QuickLinksComponent>
          ))}
        </QuickLinksComponent>
      ))}
    </QuickLinksComponent>
  </StyledQuickLinksComponentWrapper>
) : null

export const QUICK_LINKS_FRAGMENT = gql`
  fragment QuickLinksFragment on homePageQuickLinks_BlockType {
    quickLinkTitle
    icon    
    quickLinks {
      ... on quickLinks_quickLink_BlockType {
        text
        subQuickLinkUrl {
          url
          target
        }
      }
    }
    quickLinkUrl {
      target
      url
    }
  }
`